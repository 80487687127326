import React from 'react'
import { Button, Grid } from '@mui/material'
import ReactModal from 'react-modal'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Logo from '../../../assets/images/logo_bg.png'
import './LocationErrorModal.scss'

type Props = {
    isOpen: boolean;
    setIsOpen?: (isOpen: boolean) => void
}
const c = 'location-error-modal'

const LocationErrorModal = (props: Props) => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  
  return (
    <ReactModal 
      shouldCloseOnOverlayClick 
      className='location-error-modal-container'
      isOpen={props.isOpen}
      ariaHideApp={false}
      onRequestClose={() => {
        props.setIsOpen?.(false)
      }}
    >
      <div className={c}>
        <div className={`${c}-header`}>
          <div className={`${c}__header-image`}>
            <img src={Logo} alt='logo' height={100} width={100} />
          </div>
        </div>
        <div className={`${c}__content`}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className={`${c}__content-text`}>{t('LOCATION_ERROR')}</div>
            </Grid>
          </Grid>
          <div className={`${c}__content-submit`}>
            <Button
              variant='contained'
              onClick={() => {
                props.setIsOpen?.(false)
              }}
              sx={{
                backgroundColor: '#ff1647',
                textTransform: 'none',
                fontWeight: 'bold',
                width: '100%',
                '&:hover': { backgroundColor: '#cc1138' },
              }}
            >
              {t('CLOSE')}
            </Button>
          </div>
        </div>
      </div>
    </ReactModal>
  )
}

export default LocationErrorModal