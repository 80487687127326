// MapComponent.tsx
import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, useMap, useMapEvents } from 'react-leaflet';
import L from 'leaflet';
import LocationMarker from './LocationMarker';
import logo from '../../assets/images/logo.png';
import LocationErrorModal from '../../pages/checkout-page/LocationErrorModal/LocationErrorModal';
import 'leaflet/dist/leaflet.css';

const logoIcon = new L.Icon({
  iconUrl: logo,
  iconSize: [30, 30],
  iconAnchor: [15, 30],
  popupAnchor: [0, -50],
});

type Props = {
  position: L.LatLng | null;
  setPosition: (position: L.LatLng) => void;
  coordinates: any;
  showMyLocation: boolean;
  setLocationFunction: any;
};
const c = 'checkout-page'

const CenterMap: React.FC<{ center: [number, number] }> = ({ center }) => {
  const map = useMap();

  useEffect(() => {
    if (center) {
      map.setView(center, 15); 
    }
  }, [center, map]);
  
  return null;
};

const CustomLayout: React.FC<Props> = (props) => {
  const [open, setOpen] = useState(false);
  const map = useMap();

  function handleLocationClick() {
    map.locate().on("locationfound", function (e) {
      props.setPosition(e.latlng);
      map.flyTo(e.latlng, map.getZoom());
      const radius = e.accuracy;
      const circle = L.circle(e.latlng, radius);

      circle.addTo(map);
    });

    map.on("locationerror", function (e) {
      setOpen(true);
    });
  }

  useMapEvents({
    click(e) {
      props.setPosition(e.latlng);
    },
  });

  useEffect(() => {
    props.setLocationFunction(handleLocationClick);
  }, []);
  
  return <LocationErrorModal isOpen={open} setIsOpen={setOpen} />;
};

const MapComponent: React.FC<Props> = (props) => {

  return (
    <MapContainer center={props.coordinates} zoom={13} style={{ height: "400px", width: "100%" }}>
      <CustomLayout {...props} />
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <LocationMarker position={props.position} setPosition={props.setPosition} showMyLocation={props.showMyLocation} />
      <CenterMap center={props.coordinates} />
      <Marker position={{lat: 48.331216, lng: 26.871270}} icon={logoIcon}></Marker>
    </MapContainer>
  );
}

export default MapComponent;