import React, { useEffect, useState } from 'react';
import { Marker, Popup, useMap, useMapEvents } from 'react-leaflet';
import L from 'leaflet';

const customIcon = new L.Icon({
  iconUrl: 'data:image/svg+xml;base64,' + btoa(`
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="48" height="48" fill="#ff1647" stroke="#ff1647" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-map-pin">
        <path d="M21 10c0 7-9 13-9 13S3 17 3 10a9 9 0 1 1 18 0z" fill="#ff1647" stroke="#ff1647"/>
        <circle cx="12" cy="10" r="3" fill="none" stroke="#ffffff" stroke-width="2"/>
      </svg>`),
  iconSize: [38, 38],
  iconAnchor: [19, 38],
  popupAnchor: [0, -38],
});

type Props = {
    position: L.LatLng | null;
    setPosition: (position: L.LatLng) => void;
    showMyLocation: boolean;
    };

const LocationMarker: React.FC<Props> = (props) => {
  return props.position === null ? null : (
    <Marker position={props.position} icon={customIcon} />
  );
};

export default LocationMarker;