import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CustomInput } from '../../components/custom-input/CustomInput'
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormHelperText,
  MenuItem,
} from '@mui/material'
import { useOutletContext } from 'react-router-dom'
import { IProduct } from '../../types/products'
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers'
import { DigitalClock } from '@mui/x-date-pickers/DigitalClock'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { useDeviceDetect } from '../../utils/use-device-detect'
import { getLocations } from '../../api/locations'
import { checkout } from '../../api/order'
import { Store } from 'react-notifications-component'
import SuccessModal from './SuccessModal/SuccessModal'
import MapComponent from '../../components/map-component/MapComponent'
import L from 'leaflet';
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationCrosshairs } from '@fortawesome/free-solid-svg-icons'
import './CheckoutPage.scss'

const c = 'checkout-page'

const CheckoutPage = () => {
  const generalInfo =
    localStorage.getItem('info') &&
    JSON.parse(localStorage.getItem('info') || '')

  const currentDate = new Date()

  const tomorrow = new Date(currentDate)

  tomorrow.setDate(currentDate.getDate() + 1)

  const nearestValidTime = new Date()

  nearestValidTime.setMinutes(
    Math.ceil(nearestValidTime.getMinutes() / 15) * 15
  )
  nearestValidTime.setSeconds(0)
  nearestValidTime.setHours(nearestValidTime.getHours() + 1)

  const { t, i18n } = useTranslation()
  const { isMobile } = useDeviceDetect()
  const { cart, setCart } = useOutletContext<any>()
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [details, setDetails] = useState('')
  const [takeaway, setTakeaway] = useState(false)
  const [date, setDate] = useState<Date | null>(new Date())
  const [time, setTime] = useState<Date | null>(nearestValidTime)
  const [loading, setLoading] = useState<boolean>(false)
  const [locations, setLocations] = useState<any>([])
  const [location, setLocation] = useState<any>(locations[0])
  const [position, setPosition] = useState<L.LatLng | null>(null)
  const [mapLink, setMapLink] = useState<string>('');
  const [showMyLocation, setShowMyLocation] = useState<boolean>(false)
  const [locationFunction, setLocationFunction] = useState<any>(null)
  const [mapError, setMapError] = useState<boolean>(false)

  const [coordinates, setCoordinates] = useState<Partial<L.LatLng>>({
    lat: 48.35890104494752,
    lng: 27.09073581686573
  });

  const [errorState, setErrorState] = useState<any>({
    name: true,
    phone: true,
    location: true,
  })

  const [clicked, setClicked] = useState<any>(false)
  const [open, setOpen] = useState(false)
  const [allHours, setAllHours] = useState(false)

  const totalPrice = cart.reduce(
    (total: number, item: IProduct) =>
      total + Number(item.price) * item.quantity!,
    0
  )

  const getTranslatedName = (item: any) => {
    if (i18n.language === 'ru' && item?.label_ru) {
      return item.label_ru
    }

    return item.label
  }

  const PAYMENT_METHODS = [
    {
      label: t('PAYMENT_CASH'),
      value: '1',
    },
    {
      label: t('PAYMENT_CARD_AT_COURIER'),
      value: '2',
    },
  ]

  const [payment, setPayment] = useState<(typeof PAYMENT_METHODS)[0]>(
    PAYMENT_METHODS[0]
  )

  function fetchLocations() {
    getLocations().then((res) => {
      setLocations(res.data)
    })
  }

  function formatPhoneNumber(phoneNumberString: string) {
    if (
      phoneNumberString.startsWith('0') ||
      phoneNumberString.startsWith('+373')
    ) {
      if (
        phoneNumberString.includes('(') ||
        phoneNumberString.includes(')') ||
        phoneNumberString.includes('-')
      ) {
        return phoneNumberString
          .replace('(', '')
          .replace(')', '')
          .replace('-', '')
          .replaceAll(' ', '')
      }

      return phoneNumberString
    }

    return `0${phoneNumberString}`
  }

  function handleCheckout() {
    setClicked(true)

    if (!name || !phone || (!location && !takeaway)) {
      scrollTo({ top: 0, behavior: 'smooth' })

      return
    }

    if(!position) {
      setMapError(true);
      scrollTo({ top: 100, behavior: 'smooth' })

      return;
    }

    const data = {
      name: name,
      phone: formatPhoneNumber(phone),
      location: location?.label || '',
      details: details,
      delivery_method: takeaway
        ? 'Pe Loc'
        : date?.toLocaleDateString('en-GB') ===
          new Date().toLocaleDateString('en-GB')
          ? 'Livrare'
          : 'Comanda Prealabila',
      payment: payment?.label,
      time: time?.toLocaleTimeString(),
      date: date?.toLocaleDateString('en-GB'),
      delivery_cost: takeaway ? 0 : location?.delivery_price,
      order_price: totalPrice,
      total_price: totalPrice + (takeaway ? 0 : location?.delivery_price),
      mapLink,
      cart,
    }

    setLoading(true)

    checkout(data)
      .then(() => {
        Store.addNotification({
          title: 'SUCCESS',
          message: t('ORDER_SUCCESS'),
          container: 'top-center',
          type: 'success',
          insert: 'top',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        })
        setOpen(true)
        setCart([])
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        Store.addNotification({
          title: 'ERROR',
          message: `
        Este o eroare in sistem, va rugam sa ne contactati pe 069 388 892 sau 068 247 847
        
        ${err.message}
        `,
          container: 'top-center',
          type: 'danger',
          insert: 'top',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        })
      })
  }

  useEffect(() => {
    fetchLocations()
  }, [])

  useEffect(() => {
    scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  useEffect(() => {
    if (clicked) {
      setErrorState(() => {
        return {
          name: !!name,
          phone: !!phone,
          location: !!location,
        }
      })
    }
  }, [name, phone, location, clicked])

  useEffect(() => {
    if (
      date?.toLocaleDateString('en-GB') ===
      new Date().toLocaleDateString('en-GB')
    ) {
      setAllHours(false)
    } else {
      setAllHours(true)
    }
  }, [date])

  useEffect(() => {
    if (position) {
      const link = `https://maps.google.com/?q=${position.lat},${position.lng}`;

      setMapLink(link);
    }
  }, [position]);

  useEffect(() => {
    const fetchCoordinates = async (location: string) => {
      if(!location) {
        setCoordinates({lat: 48.331216, lng: 26.871270});

        return;
      }

      try {
        const response = await axios.get(`https://nominatim.openstreetmap.org/search`, {
          params: {
            q: `${location}, Briceni, Moldova`,
            format: 'json',
          },
        });

        if (response.data && response.data.length > 0) {
          const { lat, lon } = response.data[0];

          setCoordinates({lat: parseFloat(lat), lng:parseFloat(lon)});
        } else {
          console.error("Location not found");
        }
      } catch (error) {
        console.error("Error fetching coordinates", error);
      }
    };

    fetchCoordinates(location?.label || '');
  }, [location]);

  const registerChildFunction = (func: any) => {
    setLocationFunction(() => func);
  };

  useEffect(() => {
    if (position) {
      setMapError(false);
    }
  }
  , [position]);
  
  return (
    <div className={c}>
      <div className={`${c}__container`}>
        <div className={`${c}__container-title`}>{t('DELIVERY')}</div>
        <div className={`${c}__container-contact`}>
          <div className={`${c}__container-contact-input`}>
            <CustomInput
              label={t('NAME')}
              error={!errorState?.name}
              value={name}
              fullWidth
              variant='filled'
              size='small'
              onChange={(e) => setName(e.target.value)}
              inputProps={{
                style: {
                  borderColor: 'red',
                },
              }}
            />
            {!errorState?.name && (
              <FormHelperText
                error={!errorState?.name}
                id='component-error-text'
              >
                {t('FIELD_REQUIRED')}
              </FormHelperText>
            )}
          </div>
          <div className={`${c}__container-contact-input`}>
            <CustomInput
              label={t('PHONE')}
              type='tel'
              error={!errorState?.phone}
              value={phone}
              fullWidth
              variant='filled'
              onChange={(e) => setPhone(e.target.value)}
              size='small'
              inputProps={{
                style: {
                  borderColor: 'red',
                },
                input: {
                  style: {
                    '& input[type=number]': {
                      '-moz-appearance': 'textfield',
                    },
                    '& input[type=number]::-webkit-outer-spin-button': {
                      '-webkit-appearance': 'none',
                      margin: 0,
                    },
                    '& input[type=number]::-webkit-inner-spin-button': {
                      '-webkit-appearance': 'none',
                      margin: 0,
                    },
                  },
                },
              }}
            />
            {!errorState?.phone && (
              <FormHelperText
                error={!errorState?.phone}
                id='component-error-text'
              >
                {t('FIELD_REQUIRED')}
              </FormHelperText>
            )}
          </div>
        </div>
        <div className={`${c}__container-location`}>
          <div className={`${c}__container-location-input`}>
            <CustomInput
              select
              fullWidth
              error={!takeaway && !errorState?.location}
              value={location || ''}
              label={t('LOCATION')}
              placeholder='location'
              backgroundColor='#F0F0F0'
              variant='filled'
              onChange={(e) => {
                setLocation(e.target.value as any)
                setTakeaway(false)
              }}
            >
              {locations.map((location: any, index: number) => (
                <MenuItem key={index} value={location}>
                  {getTranslatedName(location)}
                </MenuItem>
              ))}
            </CustomInput>
            {!takeaway && !errorState?.location && (
              <FormHelperText
                error={!errorState?.location}
                id='component-error-text'
              >
                {t('FIELD_REQUIRED')}
              </FormHelperText>
            )}
          </div>
          <div className={`${c}__container-location-input`}>
            <FormControlLabel
              label={t('PICKUP')}
              control={
                <Checkbox
                  size='small'
                  checked={takeaway}
                  onChange={(e) => {
                    setTakeaway(e.target.checked)

                    if (e.target.checked) {
                      setLocation(null)
                      setPosition({lat: 48.331216, lng: 26.871270} as L.LatLng);
                      setCoordinates({lat: 48.331216, lng: 26.871270});
                    } else {
                      setPosition(null);
                    }
                  }}
                  style={{
                    borderColor: 'red',
                    color: '#ff1647',
                  }}
                />
              }
            />
            <a
              className={`${c}__container-location-input-map`}
              target='_blank'
              rel='noreferrer'
              onClick={(e) => {
                e.preventDefault();
                setPosition({lat: 48.331216, lng: 26.871270} as L.LatLng);
                setCoordinates({lat: 48.331216, lng: 26.871270});
              }}
            >
              {t('LOCATION_MAP')}
            </a>
          </div>
          <div className={`${c}__container-location-map-container`}>
            <div className={`${c}__container-location-map ${mapError ? 'map-error' : ''}`}>
              <MapComponent position={position} setPosition={setPosition} coordinates={coordinates} showMyLocation={showMyLocation} setLocationFunction={registerChildFunction} />
              <div className={`${c}__container-location-map-text`}>{t('SELECT_DELIVERY_LOCATION')}</div>
              <div className={`${c}__container-location-map-current`}>
                <Button
                  variant='contained'
                  disabled={loading}
                  onClick={() => locationFunction()}
                  sx={{
                    backgroundColor: '#ff1647',
                    textTransform: 'none',
                    fontSize: '16px',
                    fontWeight: 'bold',
                    width: '100%',
                    display: 'flex',
                    gap: '10px',
                    opacity: 0.5,
                    '&:hover': { backgroundColor: '#cc1138' },
                  }}
                >
                  <FontAwesomeIcon icon={faLocationCrosshairs} />  {t('SHOW_MY_LOCATION')}
                </Button>
              </div>
            </div>
            {mapError ? <p className='map-error-text'>{t('MAP_ERROR')}</p> : null}
          </div>
          <div className={`${c}__container-location-input`}>
            <CustomInput
              label={t('DETAILS')}
              fullWidth
              value={details}
              multiline
              onChange={(e) => setDetails(e.target.value)}
              rows={3}
              variant='filled'
              size='small'
              inputProps={{
                style: {
                  borderColor: 'red',
                },
              }}
            />
          </div>
        </div>
        <div className={`${c}__container-delivery_method`}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <div className={`${c}__container-delivery_method-order-tab`}>
              <MobileDatePicker
                views={['month', 'day']}
                minDate={new Date()}
                defaultValue={new Date()}
                value={date}
                timezone='Europe/Chisinau'
                onChange={(newValue) => setDate(newValue)}
                slotProps={{
                  textField: {
                    variant: 'outlined',
                    sx: {
                      outline: 'none',
                      '.Mui-focused': {
                        boxShadow: '#ff1647 0 0 0 2px',
                        background: '#fff',
                        outline: '#ff1647',
                        borderColor: 'none',
                        '& .MuiOutlinedInput-notchedOutline': {
                          border: 'none !important',
                        },
                      },
                      '.MuiOutlinedInput-root:focus-visible': {
                        outline: 'none',
                      },
                      '.MuiOutlinedInput-root:focus': {
                        outline: 'none',
                      },
                    },
                  },
                  day: {
                    sx: {
                      '&.MuiPickersDay-root.Mui-selected': {
                        backgroundColor: '#ff1647',
                      },
                    },
                  },
                  actionBar: {
                    sx: {
                      '.MuiButton-root': {
                        color: '#ff1647',
                      },
                    },
                  },
                }}
              />
              <div className={`${c}__digital-clock`}>
                {allHours ? (
                  <DigitalClock
                    timeStep={15}
                    skipDisabled
                    shouldDisableTime={() => false}
                    timezone='Europe/Chisinau'
                    sx={{
                      '.MuiDigitalClock-item': {
                        transition: 'all 0.2s ease-in-out',
                        '&.Mui-selected': {
                          backgroundColor: '#ff1647',
                          '&:hover': {
                            backgroundColor: isMobile ? 'none' : '#cc1138',
                          },
                        },
                      },
                      '.MuiDigitalClock-item:hover': {
                        backgroundColor: isMobile ? 'none' : '#cc1138',
                        color: isMobile ? '#000000' : '#ffffff',
                      },
                    }}
                    minTime={
                      new Date(
                        0,
                        0,
                        0,
                        Number(generalInfo?.minTime.split(':')[0])
                      )
                    }
                    maxTime={
                      new Date(
                        0,
                        0,
                        0,
                        Number(generalInfo?.maxTime.split(':')[0])
                      )
                    }
                    ampm={false}
                    value={time}
                    onChange={(newValue) => {
                      setTime(newValue)
                    }}
                  />
                ) : (
                  <DigitalClock
                    timeStep={15}
                    skipDisabled
                    timezone='Europe/Chisinau'
                    shouldDisableTime={() => false}
                    sx={{
                      '.MuiDigitalClock-item': {
                        transition: 'all 0.2s ease-in-out',
                        '&.Mui-selected': {
                          backgroundColor: '#ff1647',
                          '&:hover': {
                            backgroundColor: isMobile ? 'none' : '#cc1138',
                          },
                        },
                      },
                      '.MuiDigitalClock-item:hover': {
                        backgroundColor: isMobile ? 'none' : '#cc1138',
                        color: isMobile ? '#000000' : '#ffffff',
                      },
                    }}
                    minTime={nearestValidTime}
                    maxTime={
                      new Date(
                        0,
                        0,
                        0,
                        Number(generalInfo?.maxTime.split(':')[0])
                      )
                    }
                    ampm={false}
                    value={time}
                    onChange={(newValue) => {
                      setTime(newValue)
                    }}
                  />
                )}
              </div>
            </div>
          </LocalizationProvider>
        </div>
        <div className={`${c}__container-title`} />
        <div className={`${c}__container-payment_method`}>
          {PAYMENT_METHODS.map((item) => (
            <div
              key={item.value}
              className={`${c}__container-payment_method-item${
                item.value === payment?.value ? ' active' : ''
              }`}
              onClick={() => setPayment(item)}
            >
              {item.label}
            </div>
          ))}
        </div>
        <div className={`${c}__container-footer`}>
          <div className={`${c}__container-footer-total`}>
            <div className={`${c}__container-footer-total-item`}>
              <div className={`${c}__container-footer-total-item-label`}>
                {t('ORDER_SUM')}
              </div>
              <div className={`${c}__container-footer-total-item-value`}>
                {totalPrice} lei
              </div>
            </div>
            <div className={`${c}__container-footer-total-item`}>
              <div className={`${c}__container-footer-total-item-label`}>
                {t('DELIVERY')}
              </div>
              <div className={`${c}__container-footer-total-item-value`}>
                {takeaway ? 0 : location?.delivery_price || 0} lei
              </div>
            </div>
            <div className={`${c}__container-footer-total-item total`}>
              <div className={`${c}__container-footer-total-item-label`}>
                {t('TOTAL')}
              </div>
              <div className={`${c}__container-footer-total-item-value`}>
                {totalPrice + (takeaway ? 0 : location?.delivery_price || 0)}{' '}
                lei
              </div>
            </div>
            <div className={`${c}__container-footer-total-checkout`}>
              <Button
                variant='contained'
                disabled={loading}
                onClick={handleCheckout}
                sx={{
                  backgroundColor: '#ff1647',
                  textTransform: 'none',
                  fontSize: '16px',
                  fontWeight: 'bold',
                  width: '100%',
                  '&:hover': { backgroundColor: '#cc1138' },
                }}
              >
                {loading ? (
                  <CircularProgress
                    size='2rem'
                    thickness={5}
                    sx={{ color: '#ff1647' }}
                  />
                ) : (
                  t('ORDER')
                )}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <SuccessModal isOpen={open} setIsOpen={setOpen} />
    </div>
  )
}

export default CheckoutPage
